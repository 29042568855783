export const contractTypesEnum = {
  TERMSOFUSE: 0,
  MANAGEDPORTFOLIO: 1,
  ALPHAADDENDUM: 2,
  POWEROFATTORNEY: 3,
  COMMITMENTTERM: 4,
  TERMOFADHESION: 5,
  SUBSCRIPTIONBULLETIN: 6,
  RENEWADDENDUM: 7,
  RENEWADDENDUMENGAGED: 8,
  RENEWADDENDUMNOTENGAGED: 9,
  CONFIDENTIALITYTERMS: 10
};

