<template>
  <v-card class="pa-8 contract-container">
    <v-icon x-large color="primary">mdi-file-sign</v-icon>
    <div v-html="contract"></div>
    <span
      v-if="User_prop.type === UserTypeEnum.Investor"
      style="display: block; font-weight: bold; text-align: center"
      >{{ $t("disclaimer_fee_renew") }}</span
    >
    <v-row class="ma-0 pa-7">
      <div class="container-button-agree-terms">
        <v-btn @click="$emit('back')" class="secondary">
          <span>{{ $t("go_back") }}</span>
        </v-btn>
        <v-btn :loading="loading" @click="renewContracts" class="primary">
          <span>{{ $t("agree_terms_renew") }}</span>
        </v-btn>
      </div>
    </v-row>
  </v-card>
</template>
<script>
import moment from "moment";
import ApiService from "@/services/ApiService";
import { contractTypesEnum } from "@/shared/enums/ContractTypesEnum.js";
import { UserTypeEnum } from "@/shared/enums/UserType.js";
import { DocumentTypeEnum } from "@/shared/enums/DocumentType.js";

export default {
  async created() {
    this.investor = this.Investor_prop;
    this.loggedUser = JSON.parse(localStorage.getItem("user"));
    await this.getContractHTML();
  },
  components: {},
  data: () => ({
    loading: false,
    apiService: new ApiService(),
    contract: null,
    loggedUser: null,
    lang: null,
    UserTypeEnum,
    DocumentTypeEnum,
  }),
  props: {
    User_prop: Object,
  },
  computed: {},
  methods: {
    async renewContracts() {
      this.loading = true;
      let url = "",
        urlRedirect = "",
        successMsg = "",
        errorMsg = "";
      if (this.User_prop.type === UserTypeEnum.Investor) {
        url = `investor/renew-contracts`;
        urlRedirect = "/pipeline";
        successMsg = "renewed_contract";
        errorMsg = "renewed_contract_failed";
      } else if (this.User_prop.type === UserTypeEnum.Banker) {
        url = `banker/renew-contracts`;
        urlRedirect = "/dashboard/banker";
        successMsg = "renewed_confidentiality";
        errorMsg = "renewed_confidentiality_failed";
      }
      await this.apiService
        .postRequest(url)
        .then((response) => {
          this.loading = false;
          if (this.User_prop.type === UserTypeEnum.Investor) {
            this.loggedUser.aYearWithoutInvestments = false;
            this.$store.commit("SET_RENEW_INVESTOR_SIGNATURE", false);
          }
          localStorage.setItem("signContractLater", true);
          localStorage.setItem("user", JSON.stringify(this.loggedUser));
          this.$router.push(urlRedirect);
          this.$toast.success(this.$t(successMsg));
        })
        .catch((error) => {
          this.$toast.error(this.$t(errorMsg));
          return error;
        });
    },

    async getContractHTML() {
      let url = "";
      let confidencialityLang =
        this.User_prop.registerDocumentType ===
        this.DocumentTypeEnum.socialNumber
          ? "pt"
          : "en";
      if (this.User_prop.type === UserTypeEnum.Investor) {
        url = `user/get-contract-html?contract=${contractTypesEnum.RENEWADDENDUM}&language=pt`;
      } else if (this.User_prop.type === UserTypeEnum.Banker) {
        url =
          `user/get-contract-html?contract=${contractTypesEnum.CONFIDENTIALITYTERMS}&language=` +
          confidencialityLang;
      }
      await this.apiService
        .getRequest(url)
        .then((response) => {
          this.contract = response.content.content;
          this.contract = this.contract.replaceAll(
            "${FULLNAME}",
            this.User_prop.fullName
          );
          this.contract = this.contract.replace(
            "${SOCIALNUMBER}",
            this.User_prop.socialNumber
          );
          this.contract = this.contract.replaceAll(
            "${CPF}",
            this.User_prop.socialNumber
          );
          this.contract = this.contract.replace(
            "${EMAIL}",
            this.User_prop.email
          );
          this.contract = this.contract.replace(
            "${DAY}",
            moment().format("DD")
          );
          this.contract = this.contract.replace(
            "${MONTH}",
            moment().locale(localStorage.lang).format("MMM")
          );
          this.contract = this.contract.replace(
            "${YEAR}",
            moment().format("YYYY")
          );
          this.contract = this.contract.replace(
            "${REGISTER_DATE}",
            moment(this.User_prop.registerDate).format("DD/MM/YYYY")
          );
          this.contract = this.contract.replace(
            "${PARTNER_REGISTERDATE}",
            moment(this.User_prop.partner.registerDate).format("DD/MM/YYYY")
          );
          if (this.User_prop.type === UserTypeEnum.Banker)
            this.contract = this.contract.replace(
              "${PARTNER}",
              this.User_prop.partner.name
            );
          return;
        })
        .catch((error) => {
          return error;
        });
    },
  },
};
</script>

<style scoped>
.container-button-agree-terms {
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.contract-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 650px;
}

@media only screen and (max-width: 769px) {
  .contract-container {
    max-width: initial;
  }
}
</style>
