import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticStyle:{"max-width":"unset"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[(_vm.step == 0)?_c(VCard,{staticClass:"pa-8",staticStyle:{"justify-content":"center","max-width":"650px","display":"flex","flex-direction":"column"}},[_c(VIcon,{attrs:{"x-large":"","color":"red"}},[_vm._v("mdi-account-lock")]),_c(VRow,{staticClass:"ma-0 pa-7"},[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticStyle:{"display":"flex","text-align":"center"}},[_vm._v(_vm._s(_vm.user.type === _vm.UserTypeEnum.Banker ? _vm.$t("blocked_banker") : _vm.$t("blocked_investor")))])]),_c(VCol,{staticStyle:{"display":"flex","justify-content":"center"}},[_c(VBtn,{staticClass:"secondary",on:{"click":_vm.cancel}},[_c('span',[_vm._v(_vm._s(_vm.$t("cancel")))])]),_c(VBtn,{staticClass:"primary",on:{"click":function($event){_vm.step = 1}}},[_c('span',[_vm._v(_vm._s(_vm.$t("continue")))])])],1)],1)],1):_vm._e(),(_vm.step == 1)?_c('Contract',{attrs:{"User_prop":_vm.user},on:{"back":function($event){_vm.step = 0}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }