<template>
  <v-container style="max-width: unset">
    <div style="display: flex; justify-content: center">
      <v-card
        v-if="step == 0"
        class="pa-8"
        style="
          justify-content: center;
          max-width: 650px;
          display: flex;
          flex-direction: column;
        "
      >
        <v-icon x-large color="red">mdi-account-lock</v-icon>
        <v-row class="ma-0 pa-7">
          <v-col cols="12">
            <span style="display: flex; text-align: center">{{
              user.type === UserTypeEnum.Banker
                ? $t("blocked_banker")
                : $t("blocked_investor")
            }}</span>
          </v-col>
          <v-col style="display: flex; justify-content: center">
            <v-btn @click="cancel" class="secondary">
              <span>{{ $t("cancel") }}</span>
            </v-btn>
            <v-btn @click="step = 1" class="primary">
              <span>{{ $t("continue") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <Contract :User_prop="user" @back="step = 0" v-if="step == 1"> </Contract>
    </div>
  </v-container>
</template>
<script>
import Contract from "./contracts/contract.vue";
import ApiService from "@/services/ApiService";
import { InvestorTypeEnum } from "@/shared/enums/InvestorTypeEnum";
import { UserTypeEnum } from "@/shared/enums/UserType";
export default {
  created() {
    this.getUser();
  },
  components: { Contract },
  data() {
    return {
      apiService: new ApiService(),
      step: 0,
      user: null,
      UserTypeEnum,
    };
  },
  methods: {
    cancel() {
      // Investidor não engajado só pode sair,
      // investidor engajado pode 'pular' a assinatura do contrato
      // Banker só pode sair
      const user = localStorage.getItem("user");
      const parsedUser = JSON.parse(user);
      if (
        parsedUser.investorType === InvestorTypeEnum.NOT_ENGAGED ||
        parsedUser.type === UserTypeEnum.Banker) {
        this.$router.push("/auth");
      } else {
        localStorage.setItem("signContractLater", true);
        this.$router.push("/pipeline");
      }
    },
    getUser() {
      this.apiService
        .getRequest(`user/user-profile`)
        .then((response) => {
          this.user = response.content;
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped></style>
